import {Fragment, useState} from 'react';
import Link from 'next/link';

import {Popover, Transition} from '@headlessui/react';
import {Hamburger, PolarSignalsFull, Close} from '@polarsignals/icons';
import {Icon} from '@iconify/react';

import {trackScheduleACallClick} from 'utils/analytics';
import UseCases from './UseCases';

const Navbar = () => {
  const [useCasesMenuOpen, setUseCasesMenuOpen] = useState(false);

  const handleMobileMenuUseCasesClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event?.preventDefault();
    setUseCasesMenuOpen(!useCasesMenuOpen);
  };

  return (
    <Popover as="nav" className="absolute z-40 w-full">
      <div className="text-md w-full px-4 py-5 text-white lg:px-10 lg:py-3">
        <div className="mx-auto flex items-center justify-between lg:px-4">
          <div className="flex w-full items-center justify-between lg:w-fit">
            <div className="w-[120px] lg:flex lg:w-[240px]">
              <Link href="/">
                <a>
                  <PolarSignalsFull className="text-white" width="100%" />
                </a>
              </Link>
            </div>

            <div className="-my-2 -mr-2 lg:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 hover:opacity-80">
                <span className="sr-only">Open menu</span>
                <Hamburger width={25} height={19} />
              </Popover.Button>
            </div>
          </div>
          <div className="hidden gap-4 lg:flex lg:items-center">
            <div className="flex">
              <UseCases />
              <div className="p-5">
                <Link href="/pricing">Pricing</Link>
              </div>
              <div className="p-5">
                <Link href="https://www.polarsignals.com/docs/overview">Documentation</Link>
              </div>
              <div className="p-5">
                <Link href="/blog">Blog</Link>
              </div>
            </div>

            <Link href="/schedule-a-call">
              <a
                className="flex grow items-center rounded-md bg-white py-3 px-5 font-sans text-base font-bold  text-gray-700 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2"
                onClick={() => trackScheduleACallClick('navbar')}
              >
                <span>Schedule a call</span>
                <Icon icon="ic:baseline-call" className="ml-2 h-[18px] w-auto" />
              </a>
            </Link>

            <Link href="https://cloud.polarsignals.com/">
              <a className="rounded-md border-2 border-white py-3 px-5 font-sans text-base font-bold text-white hover:bg-white hover:text-gray-700 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2">
                <span>Sign in</span>
              </a>
            </Link>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="fixed inset-x-0 top-0 origin-top-right transform p-3 transition lg:hidden"
          >
            {({close}) => (
              <div className="flex h-[calc(100vh-24px)] flex-col justify-between divide-y-2 divide-gray-50 overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="bg-brand-dark-blue flex h-[77px] w-full items-center justify-between px-3 py-3.5">
                  <div className="w-[214px]">
                    <Link href="/">
                      <a>
                        <PolarSignalsFull className="text-white" width="100%" />
                      </a>
                    </Link>
                  </div>
                  <Popover.Button>
                    <span className="sr-only">Close menu</span>
                    <Close width={24} height={24} />
                  </Popover.Button>
                </div>
                <div className="flex w-full grow flex-col items-center justify-between bg-white px-4">
                  <nav className="grid gap-y-12 pt-12">
                    <Popover.Button as="div">
                      <div className="flex flex-col items-center justify-center gap-4">
                        <button
                          onClick={handleMobileMenuUseCasesClick}
                          className="flex items-center text-center text-2xl font-medium text-gray-900"
                        >
                          <span>Use Cases</span>
                          <Icon icon="ph:caret-down-bold" className="ml-1 h-[15px] w-auto" />
                        </button>
                        {useCasesMenuOpen && (
                          <div className="flex flex-col gap-4">
                            <Link href="/use-cases/understand-incidents">
                              <a className="text-center text-xl font-medium text-gray-800">
                                Understand Incidents
                              </a>
                            </Link>
                            <Link href="/use-cases/optimize-performance">
                              <a className="text-center text-xl font-medium text-gray-800">
                                Optimize Performance
                              </a>
                            </Link>
                            <Link href="/use-cases/save-infrastructure-costs">
                              <a className="text-center text-xl font-medium text-gray-800">
                                Save Infrastructure Costs
                              </a>
                            </Link>
                          </div>
                        )}
                      </div>
                    </Popover.Button>
                    <Popover.Button as="div">
                      <Link href="/pricing">
                        <a
                          className="flex items-center justify-center rounded-md"
                          onClick={() => close()}
                        >
                          <span className="text-2xl font-medium text-gray-900">Pricing</span>
                        </a>
                      </Link>
                    </Popover.Button>
                    <Popover.Button as="div">
                      <Link href="https://www.polarsignals.com/docs/overview" target="_blank">
                        <a className="flex items-center justify-center rounded-md">
                          <span className="text-2xl font-medium text-gray-900">Documentation</span>
                        </a>
                      </Link>
                    </Popover.Button>
                    <Popover.Button as="div">
                      <Link href="/blog">
                        <a
                          className="flex items-center justify-center rounded-md"
                          onClick={() => close()}
                        >
                          <span className="text-2xl font-medium text-gray-900">Blog</span>
                        </a>
                      </Link>
                    </Popover.Button>
                  </nav>
                  <div className="flex w-full flex-col items-center justify-center gap-5 pb-12">
                    <Link href="/schedule-a-call">
                      <a className="flex w-full justify-center rounded-md bg-gray-800 py-3 px-5 text-center font-sans text-base font-normal uppercase text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2">
                        <span>Schedule a call</span>
                        <Icon icon="ic:baseline-call" className="ml-2 h-[18px] w-auto" />
                      </a>
                    </Link>

                    <Link href="https://cloud.polarsignals.com/">
                      <a className="w-full rounded-md border border-black bg-white py-3 px-5 text-center font-sans text-base font-normal uppercase text-gray-700 hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2">
                        Sign in
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
};

export default Navbar;
