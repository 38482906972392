import {track} from '@vercel/analytics';

export const EVENTS = {
  SCHEDULE_A_CALL_CLICK: 'schedule-a-call-click',
  SIGN_UP_CLICK: 'sign-up-click',
  PRICING_CALCULATOR_INTERACTED: 'pricing-calculator-interacted',
};

export function trackEvent(name: string, properties?: Record<string, string | number | boolean>) {
  track(name, properties);
}

export function trackScheduleACallClick(
  source?: string,
  properties?: Record<string, string | number | boolean>
) {
  trackEvent(
    EVENTS.SCHEDULE_A_CALL_CLICK,
    source != null ? {source, ...(properties ?? {})} : properties
  );
}

export function trackSignUpClick(
  source?: string,
  properties?: Record<string, string | number | boolean>
) {
  trackEvent(EVENTS.SIGN_UP_CLICK, source != null ? {source, ...(properties ?? {})} : properties);
}
