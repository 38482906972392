import {MDXProvider} from '@mdx-js/react';
import {Analytics} from '@vercel/analytics/react';
import {DefaultSeo} from 'next-seo';
import type {AppProps} from 'next/app';
import Code from '../components/Code';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import {NextQueryParamProvider} from 'next-query-params';
import {IntercomProvider} from 'providers/IntercomProvider';

import '@fontsource/open-sans';
import './App.scss';
import './globals.scss';

const components = {
  code: Code,
};

function MyApp({Component, pageProps}: AppProps) {
  return (
    <IntercomProvider>
      <NextQueryParamProvider>
        <MDXProvider components={components}>
          <DefaultSeo
            title="Debug Daily. Optimize Always | Polar Signals"
            description="Polar Signals Cloud is an always-on, zero-instrumentation continuous profiling product
            that helps improve performance, understand incidents, and lower infrastructure costs."
            openGraph={{
              type: 'website',
              locale: 'en_IE',
              url: 'https://polarsignals.com/',
              site_name: 'Debug Daily. Optimize Always | Polar Signals',
              description:
                'Polar Signals Cloud is an always-on, zero-instrumentation continuous profiling product that helps improve performance, understand incidents, and lower infrastructure costs.',
              title: 'Debug Daily. Optimize Always | Polar Signals',
              images: [{url: 'https://polarsignals.com/og-image.png'}],
            }}
            twitter={{
              handle: '@PolarSignalsIO',
              site: '@PolarSignalsIO',
              cardType: 'summary_large_image',
            }}
            additionalLinkTags={[
              {rel: 'icon', href: '/favicon.png'},
              {
                rel: 'alternate',
                type: 'application/rss+xml',
                href: 'https://www.polarsignals.com/rss.xml',
              },
            ]}
          />

          <Navbar />

          <Component {...pageProps} />
          <Analytics />
          <Footer />
        </MDXProvider>
      </NextQueryParamProvider>
    </IntercomProvider>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }

export default MyApp;
