import {PolarSignalsFull} from '@polarsignals/icons';
import Link from 'next/link';
import {trackScheduleACallClick} from 'utils/analytics';

const Footer = () => {
  return (
    <footer className="bg-black">
      <div className="container mx-auto flex w-full flex-col gap-12 py-12 px-4 md:px-8 lg:flex-row lg:gap-0 lg:py-32">
        <div className="gap-11.5 grid grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col gap-3">
            <p className="!mb-0 text-white">Company</p>
            <Link href="/about-us">
              <a className="text-brand-gray font-sans">About Us</a>
            </Link>
            <Link href="/blog">
              <a className="text-brand-gray font-sans">Blog</a>
            </Link>
            <Link href="/tos">
              <a className="text-brand-gray font-sans">Terms of Service</a>
            </Link>
            <Link href="/privacy">
              <a className="text-brand-gray font-sans">Privacy Policy</a>
            </Link>
            <Link href="/working-at-polar-signals">
              <a className="text-brand-gray font-sans">Working at Polar Signals</a>
            </Link>
          </div>

          <div className="flex flex-col gap-3">
            <p className="!mb-0 text-white">Support</p>
            <Link href="/pricing">
              <a className="text-brand-gray font-sans">Pricing</a>
            </Link>
            <Link href="/#faq">
              <a className="text-brand-gray font-sans">FAQs</a>
            </Link>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://polarsignals.com/docs"
              className="text-brand-gray font-sans"
            >
              Docs
            </a>
            <a
              href="mailto:support@polarsignals.com"
              target="_blank"
              rel="noreferrer"
              className="text-brand-gray font-sans"
            >
              Contact Us
            </a>
            <Link href="/schedule-a-call">
              <a
                className="text-brand-gray font-sans"
                onClick={() => trackScheduleACallClick('footer')}
              >
                Schedule a call
              </a>
            </Link>
          </div>

          <div className="flex flex-col gap-3">
            <p className="!mb-0 text-white">Product</p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://pprof.me"
              className="text-brand-gray font-sans"
            >
              pprof.me
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://parca.dev/"
              className="text-brand-gray font-sans"
            >
              Parca
            </a>
          </div>

          <div className="flex flex-col gap-3">
            <p className="!mb-0 text-white">Social</p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/PolarSignalsIO"
              className="text-brand-gray font-sans"
            >
              Twitter
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/polarsignals"
              className="text-brand-gray font-sans"
            >
              GitHub
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://discord.gg/knw3u5X9bs"
              className="text-brand-gray font-sans"
            >
              Discord
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@PolarSignalsIO"
              className="text-brand-gray font-sans"
            >
              YouTube
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center justify-between gap-3 lg:ml-auto lg:items-end lg:gap-0">
          <Link href="/">
            <a>
              <PolarSignalsFull width="200" className="text-white" />
            </a>
          </Link>

          <p className="text-brand-gray !mb-0 text-center font-sans text-base font-normal lg:text-right">
            Copyright © 2023 Polar Signals. All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
